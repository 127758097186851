$(window).resize(function() {
  getWidthSelect01();
  // inputUsoPruebasWidth();
})

// OBJETO GLOBAL FORM VARIABLES
const formData = {
  nombreEmpresa: '',
  nit: '',
  telefono: '',
  nombre: '',
  correo: '',
  
  cargo: '',
  cargoOtro: '',
  // usoPruebas: '',
  // usoPruebasOtras: '',

  origenUTM: '',
  urlInicial: '',
  urlHistorial: '',
  urlOrigenUTM: '',
  reCaptchaResponse: ''
}


// INPUTS QUERY SELECTOR
const nombreEmpresa = document.querySelector('#nombre-empresa');
const nit = document.querySelector('#nit');
const telefono = document.querySelector('#telefono');

const nombre = document.querySelector('#nombre');
const correo = document.querySelector('#correo');

const cargo = document.querySelector('#cargo');
const cargoOtro = document.querySelector('#cargo-otro');

// const usoPruebas = document.querySelector('#uso-pruebas');
// const usoPruebasOtras = document.querySelector('#uso-pruebas-otras');

const origenUTM = document.querySelector('#origenUTM');
const urlInicial = document.querySelector('#urlInicial');
const urlHistorial = document.querySelector('#urlHistorial');
const urlOrigenUTM = document.querySelector('#urlOrigenUTM');

// Form
const formularioContacto = document.querySelector("#formulario-contacto");

// Subtmit Btn
const submitBtn = document.querySelector('#submit-form');

const asignarFormData = (dataVar, dataItem) => {
  dataVar.addEventListener('input', (event) => {
    const value = event.target.value;

    if(dataItem === 'nombreEmpresa'){
      formData.nombreEmpresa = value;
    }
    else if(dataItem === 'nit'){
      formData.nit = value;
    }
    else if(dataItem === 'telefono'){
      formData.telefono = value;
    }

    else if(dataItem === 'nombre'){
      formData.nombre = value;
    }
    else if(dataItem === 'correo'){
      formData.correo = value;
    }

    else if(dataItem === 'cargoOtro'){
      formData.cargoOtro = dataVar.value;
    }

    
    else if(dataItem === 'origenUTM'){
      formData.origenUTM = value;
    }
    else if(dataItem === 'urlInicial'){
      formData.urlInicial = value;
    }
    else if(dataItem === 'urlHistorial'){
      formData.urlHistorial = value;
    }
    else if(dataItem === 'urlOrigenUTM'){
      formData.urlOrigenUTM = value;
    }
    else{
      return;
    }

    // console.log(formData);
  });

  const asignacionDirecta = () => {
    if(dataItem === 'cargo'){
      formData.cargo = dataVar.value;
    }
    // else if(dataItem === 'usoPruebas'){
    //   formData.usoPruebas = dataVar.value;
    // }

    else if(dataItem === 'origenUTM'){
      formData.origenUTM = dataVar.value;
    }
    else if(dataItem === 'urlInicial'){
      formData.urlInicial = dataVar.value;
    }
    else if(dataItem === 'urlHistorial'){
      formData.urlHistorial = dataVar.value;
    }
    else if(dataItem === 'urlOrigenUTM'){
      formData.urlOrigenUTM = dataVar.value;
    }
    else if (dataItem === 'reCaptchaResponse'){
      formData.reCaptchaResponse = dataVar.value;
    }
    else{
      return;
    }
    // console.log(formData);
  }
  asignacionDirecta();
}

// Cargo
// SELECT EVENTS COLLAPSE
const getWidthSelect01 = () => {
  const inputAncho = $('#cargo').outerWidth();
  if (($(window).width() < 992)){
    $('#collapseSelectCargo').css('width','100%');
  }
  else {
    $('#collapseSelectCargo').css('width',inputAncho+'px');
  }
}

const inputCargoWidth = () => {
  $('#collapseSelectCargo').on('show.bs.collapse', function () {
    const inputAncho = $('#cargo').outerWidth();
    $('#collapseSelectCargo').css('width',inputAncho+'px');
    // $('#collapseUsoPruebas').collapse('hide');

    $('#select-01').removeClass('arrow-down');
    $('#select-01').addClass('arrow-up');
  })

  $('#collapseSelectCargo').on('hide.bs.collapse', function () {
    $('#select-01').removeClass('arrow-up');
    $('#select-01').addClass('arrow-down');
  })
}
inputCargoWidth();

const selectCargo = () => {
  $('.event-cargo').click( function(event){
    let dataClick = $(event.target).data('click');
    if (dataClick === 'Otro'){
      // $('#cargo-otro').prop('required', true);
      $('#input-cargo-otro').removeClass('d-none');

      $('#cargo-otro').removeClass('d-none');
      $('#cargo-otro').focus();
  
      $('#collapseSelectCargo').collapse('hide');
      $('#cargo').attr('value', dataClick);

      asignarFormData(cargo, 'cargo');
      asignarFormData(cargoOtro, 'cargoOtro');
    }
    else{
      // $('#cargo-otro').prop('required', false);
      // $('#cargo-otro p').remove();

      $('#input-cargo-otro').addClass('d-none');
  
      $('#collapseSelectCargo').collapse('hide');
      $('#cargo').attr('value', dataClick);

      asignarFormData(cargo, 'cargo');
      formData.cargoOtro = '';
    }
  })
}
selectCargo();


// Uso pruebas
// const inputUsoPruebasWidth = () => {
//   $(window).resize(function() {
//     const inputAncho = $('#cargo').outerWidth();
//     if (($(window).width() < 992)){
//       $('#collapseUsoPruebas').css('width','100%');
//     }
//     else {
//       $('#collapseUsoPruebas').css('width',inputAncho+'px');
//     }
//   })

//   $('#collapseUsoPruebas').on('show.bs.collapse', function () {
//     const inputAncho = $('#uso-pruebas').outerWidth();
//     $('#collapseUsoPruebas').css('width',inputAncho+'px');
//     $('#collapseSelectCargo').collapse('hide');

//     $('#select-02').removeClass('arrow-down');
//     $('#select-02').addClass('arrow-up');
//   })

//   $('#collapseUsoPruebas').on('hide.bs.collapse', function () {
//     $('#select-02').removeClass('arrow-up');
//     $('#select-02').addClass('arrow-down');
//   })
// }
// inputUsoPruebasWidth();

// const selectUsoPruebas = () =>{
//   $('.event-uso-pruebas').click( function(event){
//     let dataClick = $(event.target).data('click');
//     // if (dataClick === 'Otra(s)'){
//     //   $('#uso-pruebas-otras').removeClass('d-none')
//     //   $('#uso-pruebas-otras').focus()
  
//     //   $('#collapseUsoPruebas').collapse('hide')
//     //   $('#uso-pruebas').attr('value', dataClick)
//     // }
//     // else{
//       // $('#uso-pruebas-otras').addClass('d-none')
  
//       // $('#collapseUsoPruebas').collapse('hide')
//       // $('#uso-pruebas').attr('value', dataClick)
//     // }

//     $('#uso-pruebas-otras').addClass('d-none')
  
//     $('#collapseUsoPruebas').collapse('hide')
//     $('#uso-pruebas').attr('value', dataClick)

//     asignarFormData(usoPruebas, 'usoPruebas');
//   })
// }
// selectUsoPruebas();

asignarFormData(nombreEmpresa, 'nombreEmpresa');
asignarFormData(nit, 'nit');
asignarFormData(telefono, 'telefono');

asignarFormData(nombre, 'nombre');
asignarFormData(correo, 'correo');

// asignarFormData(cargo, 'cargo');
// asignarFormData(cargoOtro, 'cargoOtro');

// asignarFormData(usoPruebas, 'usoPruebas');


asignarFormData(origenUTM, 'origenUTM');
asignarFormData(urlInicial, 'urlInicial');
asignarFormData(urlHistorial, 'urlHistorial');
asignarFormData(urlOrigenUTM, 'urlOrigenUTM');

// Ingresa solo numeros a los inputs de Nit -> -
const soloNumerosNit = (evt) => {
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  // if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  if ((charCode < 45 || charCode > 45) && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

// Ingresa solo numeros a los inputs de Telefono 0 -> 9
const soloNumeros = (evt) => {
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

const validatePathResponse = () => {
  const pathPhp = '/includes/tracking/process-form.php';
  const pathJs = '/mensaje-enviado.php';

  sendForm(pathPhp, pathJs);
  // sendForm(pathPhp);
}

// ReCaptcha
const validateRecaptcha = () => {
  const mgsRecaptcha = document.querySelector('#msgSubmitRecaptcha');

  if (grecaptcha && grecaptcha.getResponse(1).length > 0) {
    mgsRecaptcha.textContent = '';
    formData.reCaptchaResponse = grecaptcha.getResponse(1);
    // console.log(grecaptcha.getResponse(0));
    
    mgsRecaptcha.classList.remove('input-error');
    return true;
  } else {
    mgsRecaptcha.textContent = 'Confirma que no eres un robot';
    // console.log(grecaptcha.getResponse(0));

    mgsRecaptcha.classList.add('input-error');
    return false;
  }
}

$("#submit-form").mouseover(function() {
  validateRecaptcha();
});

const pristineForm = () => {
  // window.onload = () => {
    // create the pristine instance
    const pristine = new Pristine(formularioContacto);

    formularioContacto.addEventListener('submit', (event) => {
      event.preventDefault();
      
      // check if the form is valid
      const valid = pristine.validate(); // returns true or false

      // console.log(formData);

      if(valid && validateRecaptcha()){
        // sendForm();
        validatePathResponse();
      }
      else{
        // console.log(valid02);
        return false;
      }

    });
  // };
}
pristineForm();


const sendForm = (pathPhp, pathJs) => {
  // mostrarCompletarCampos('', true, padreSubmitForm);
  submitBtn.classList.add('d-none');

  $("#formulario-contacto").addClass("animacion-form");
  if($("#formulario-contacto").hasClass("animacion-form")) {
    setTimeout(() => { 
      $("#formulario-contacto").addClass("d-none");
      // $('#ancla-seccion-form').scrollTop(0);
      $("#procesando-datos").removeClass("d-none");
      $("#procesando-datos").addClass("d-flex suavizar-opacidad");
    }, 700);
  }
  else{
    $("#formulario-contacto").removeClass("animacion-form");
    $("#formulario-contacto").removeClass("d-none");
  }

  // postData(formData, pathPhp, pathJs);
  postData(formData, pathPhp, pathJs);
} 

// async function postData(formattedformData, pathPhp, pathJs){
async function postData(formattedformData, pathPhp, pathJs){
  const response = await fetch(pathPhp, {
    method: 'POST',
    body: JSON.stringify(formattedformData)
  });

  const data = await response.text();
  console.log(data);
  // const data = await response.json();

  //This should later print out the values submitted through the form
  // console.log(data);
  if (data === "Completado") {
    formularioContacto.reset();
    window.location.href = pathJs;
  } else {
    console.log('Algo salio muy mal :(');
  }
}
